import type { AxiosError } from 'axios';
import axios from 'axios';
import type { TypedUseSelectorHook } from 'react-redux';
import { useDispatch, useSelector } from 'react-redux';

import { Device } from '@capacitor/device';
import type { UnknownAction } from '@reduxjs/toolkit';
import { combineReducers, configureStore, isAction } from '@reduxjs/toolkit';

import {
  appStatusSlice,
  fetchGuestInfo,
  setExpiredTokenErrorToast,
  setNetworkErrorToast,
} from './components/updateApp/appStatusSlice';
import { environment } from './environment/environment';
import { getOrgCode } from './helpers/device.helper';
import { history } from './history';
import { advocateAccountSlice } from './pages/advocate/AdvocateAccountSlice';
import { advocateAuthSlice } from './pages/advocate/AdvocateAuthSlice';
import { advocateChatSlice, setAdvocateUnavailable } from './pages/advocate/AdvocateChatSlice';
import { firstAlertsSlice } from './pages/guest/firstAlertsSlice';
import { guestChatSlice } from './pages/guest/guestChatSlice';
import { guestSlice } from './pages/guest/guestSlice';
import { lepsSlice } from './pages/guest/lepsSlice';
import { servicesSlice } from './pages/guest/servicesSlice';
import { removeStoredAuthAsync } from './services/login';
import { RaygunErrorHandlerService } from './services/raygun';

const { setRaygunUser } = RaygunErrorHandlerService();

const reducers = combineReducers({
  advocateChatSlice: advocateChatSlice.reducer,
  advocateAccountSlice: advocateAccountSlice.reducer,
  appStatusSlice: appStatusSlice.reducer,
  guestChatSlice: guestChatSlice.reducer,
  firstAlertsSlice: firstAlertsSlice.reducer,
  lepsSlice: lepsSlice.reducer,
  servicesSlice: servicesSlice.reducer,
  guestSlice: guestSlice.reducer,
  advocateAuthSlice: advocateAuthSlice.reducer,
});

type StateType = ReturnType<typeof reducers>;

const rootReducer = (state: StateType | undefined, action: UnknownAction) => {
  if (isAction(action) && action.type === 'CLEAR_ALL_STATES') {
    return reducers(undefined, action);
  }
  return reducers(state, action);
};

export const clearAllStates = () => {
  return { type: 'CLEAR_ALL_STATES' };
};

let deviceInfo:
  | {
      device_id: string;
      device_os: 'ios' | 'android' | 'web';
      device_model: string;
      device_width: number;
      device_height: number;
      org_code: string;
    }
  | undefined;
const setAndGetDeviceInfo = async () => {
  const { identifier } = await Device.getId();
  const { platform, model } = await Device.getInfo();
  const device_height = window.screen.height;
  const device_width = window.screen.width;
  localStorage.setItem('device_id', identifier);
  localStorage.setItem('device_os', platform);
  const org_code = getOrgCode();
  deviceInfo = {
    device_id: identifier,
    device_os: platform,
    device_model: model,
    device_width,
    device_height,
    org_code,
  };
  return deviceInfo;
};

setAndGetDeviceInfo();
axios.defaults.baseURL = environment.baseEndpoint;
axios.defaults.responseType = 'json';
axios.defaults.headers.common['Content-Type'] = 'application/json';
axios.defaults.headers.common['appversion'] = `${environment.versionNumber}.${environment.buildNumber}`;

const setInterceptors = (createdStore: StoreType) => {
  const { dispatch } = createdStore;

  axios.interceptors.request.use(
    async (config) => {
      const deviceData = deviceInfo ?? (await setAndGetDeviceInfo());
      config.data = config.data ? { ...config.data, ...deviceData } : deviceData;
      const token = createdStore.getState().advocateAuthSlice.authResult.token;
      if (!config.headers.get('authorization') && token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
      return config;
    },
    (error) => Promise.reject(error),
  );

  axios.interceptors.response.use(
    (next) => Promise.resolve(next),
    async (err) => {
      const error = err as AxiosError;
      if (error.message === 'Network Error') {
        dispatch(setNetworkErrorToast(true));
      }

      const token = createdStore.getState().advocateAuthSlice.authResult.token;
      if (error.response?.status === 401 && token) {
        await logout(dispatch, true);
        history.push('/advocate-login');
        dispatch(setExpiredTokenErrorToast(true));
      }

      return Promise.reject(error);
    },
  );
};

export const createStore = () => {
  const store = configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
  });
  setInterceptors(store);
  return store;
};

export const store = createStore();

type StoreType = ReturnType<typeof createStore>;

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = StateType;
export type AppDispatch = StoreType['dispatch'];

export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;

export const logout = async (dispatch: AppDispatch, is401?: boolean) => {
  if (!is401) {
    await dispatch(setAdvocateUnavailable());
  }
  await removeStoredAuthAsync();
  await setRaygunUser();
  dispatch(clearAllStates());
  await dispatch(fetchGuestInfo());
};
