import { forwardRef } from 'react';
import type React from 'react';

import { IonInput } from '@ionic/react';

import './TSInput.scss';

type InputVariant = 'input' | 'password-input';

type IonInputProps = React.ComponentProps<typeof IonInput>;

interface TSInputProps extends IonInputProps {
  variant?: InputVariant;
}

export const TSInput = forwardRef<HTMLIonInputElement, TSInputProps>(function BasicTSInput(
  { variant = 'input', label, children, className = '', ...props }: TSInputProps,
  ref,
) {
  const combinedClassNames = `ts-${variant} ${className}`;

  return (
    <>
      {label && <p>{label}</p>}
      <IonInput className={combinedClassNames} mode="md" fill="outline" {...props} label={undefined} ref={ref}>
        {children}
      </IonInput>
    </>
  );
});
