export const environment = {
  baseEndpoint: 'https://api-dev.talkingstick.app/',
  buildNumber: "28811188",  
  versionNumber: "2.1.8",  
  environment_deploy: 'dev',
  rayGunKey: 'hqaZDpkENyp0WOVqpDhdg',
  authResultKey: '4c97e2d0-e050-4bf0-8b88-0e97573306f4',
  cryptoKey: '31acc3bb-8543-4b00-bd3e-3fb96d06c443',
  posthogToken: 'phc_FxfNWKWqYxa9nm24KsWccHzMR776akapxZWqhHjFTGJ',
  orgCode: 'sk',

  firebaseConfig: {
    apiKey: 'AIzaSyCLDHbBwV_P4NaoQKLKDw5YFHUpUmVD_14',
    authDomain: 'talking-stick-dev-f04da.firebaseapp.com',
    projectId: 'talking-stick-dev-f04da',
    storageBucket: 'talking-stick-dev-f04da.appspot.com',
    messagingSenderId: '777694147085',
    appId: '1:777694147085:web:c35f67b3a28e2514295b44',
    vapidKey: 'BMkP8WV0peP5ZBQbr6jiFatOC2NLUWBd-IvJrlYYBzD6yqRHUXaE2S_5ftt2yD7el7krAYvinfMfzAKWiy_IcHU',
  },
};
