import { lazy, Suspense } from 'react';
import { Redirect, Route, Switch } from 'react-router';

import { IonRouterOutlet, IonSpinner } from '@ionic/react';

import { getAnimationBuilder, isNative } from '../helpers/device.helper';
import { AuthRoute, DesignRoute } from '../helpers/router.helper';
import { useWebNotificationListener } from '../hooks';
import { useCapturePostHogPageView } from '../hooks/useCapturePostHogPageView';

const AdvocateForgotPasswordPage = lazy(() => import('../pages/advocate/AdvocateForgotPassword.page'));
const AdvocateLoginPage = lazy(() => import('../pages/advocate/AdvocateLogin.page'));
const AdvocateResetPasswordPage = lazy(() => import('../pages/advocate/AdvocateResetPassword.page'));
const AdvocateTabs = lazy(() => import('./AdvocateTabs.router'));
const DesignPage = lazy(() => import('../pages/design/design.page'));
const GuestTabs = lazy(() => import('./GuestTabs.router'));
const InitialOnboardingPage = lazy(() => import('../pages/onboarding/InitialOnboarding.page'));
const SettingsPage = lazy(() => import('../pages/settings/Settings.page'));
const AboutPage = lazy(() => import('../pages/about/About.page'));

const guestPaths = isNative() ? ['/guest/chat', '/guest/services', '/guest/alert'] : ['/guest/chat', '/guest/services'];
const advocatePaths = ['/advocate/chat', '/advocate/resources', '/advocate/account', '/advocate/logout'];

export const MiddlewareRouter = () => {
  useWebNotificationListener();
  useCapturePostHogPageView();

  return (
    <Switch>
      <Route exact path={guestPaths}>
        <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
          <GuestTabs />
        </Suspense>
      </Route>
      <AuthRoute exact path={advocatePaths}>
        <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
          <AdvocateTabs />
        </Suspense>
      </AuthRoute>
      <IonRouterOutlet animation={getAnimationBuilder}>
        <Route exact path="/(loading|onboarding)">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <InitialOnboardingPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-login">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <AdvocateLoginPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-forgot-password">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <AdvocateForgotPasswordPage />
          </Suspense>
        </Route>
        <Route exact path="/advocate-reset-password/:token">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <AdvocateResetPasswordPage />
          </Suspense>
        </Route>
        <Route exact path="/settings">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <SettingsPage />
          </Suspense>
        </Route>
        <Route exact path="/about">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <AboutPage />
          </Suspense>
        </Route>
        <DesignRoute exact path="/design">
          <Suspense fallback={<IonSpinner className="fallback-spinner" />}>
            <DesignPage />
          </Suspense>
        </DesignRoute>
        <Redirect to="/loading" />
      </IonRouterOutlet>
    </Switch>
  );
};
