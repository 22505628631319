import { getOrgCode, isBurns } from './helpers/device.helper';

const orgCode = getOrgCode();
const documentFragment = document.createDocumentFragment();

const manifestLink = document.createElement('link');
manifestLink.rel = 'manifest';
manifestLink.href = `/manifest-${orgCode}.json`;
documentFragment.appendChild(manifestLink);

const faviconLink = document.createElement('link');
faviconLink.rel = 'shortcut icon';
faviconLink.type = 'image/png';
faviconLink.href = `/assets/icons/icon-${orgCode}-48.webp`;
documentFragment.appendChild(faviconLink);

const title = document.createElement('title');
const titleContent = isBurns ? 'the Burns Way' : 'Talking Stick';
title.text = titleContent;
documentFragment.appendChild(title);

const appleAppTitleMeta = document.createElement('meta');
appleAppTitleMeta.name = 'apple-mobile-web-app-title';
appleAppTitleMeta.content = titleContent;
documentFragment.appendChild(appleAppTitleMeta);

// Add Apple Smart App Banner
const appleiTunesAppMeta = document.createElement('meta');
appleiTunesAppMeta.name = 'apple-itunes-app';
appleiTunesAppMeta.content = isBurns ? 'app-id=<TODO:Add prod Burns Way app id here>' : 'app-id=1611790835';
documentFragment.appendChild(appleiTunesAppMeta);

document.head.appendChild(documentFragment);
