import type React from 'react';

import { IonButton } from '@ionic/react';

import './TSButton.scss';

import { isBurns } from '../../../helpers/device.helper';

export type ButtonVariant =
  | 'default'
  | 'red'
  | 'blue'
  | 'blue-60'
  | 'cyan'
  | 'underline'
  | 'underline-light'
  | 'white'
  | 'black'
  | 'brown'
  | 'grey8'
  | 'burns-gold'
  | 'burns-black';

type IonButtonProps = React.ComponentProps<typeof IonButton>;

interface TSButtonProps extends IonButtonProps {
  variant?: ButtonVariant;
  disabled?: boolean;
  shadow?: boolean;
  textFontClassName?: string;
}

export const TSButton: React.FC<TSButtonProps> = ({
  variant = isBurns ? 'burns-gold' : 'default',
  disabled = false,
  shadow = false,
  children,
  className = '',
  textFontClassName,
  ...props
}) => {
  const combinedClassNames = `title-medium ts-button ${disabled ? 'disabled' : ''} ${shadow ? 'ts-shadow' : ''} ts-${variant} ${className}`;

  return (
    <IonButton
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      disabled={disabled}
      className={combinedClassNames}
      {...props}
    >
      {textFontClassName ? <span className={`${textFontClassName} ts-${variant}`}>{children}</span> : children}
    </IonButton>
  );
};
