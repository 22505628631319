import { useEffect } from 'react';
import { useHistory } from 'react-router';

import {
  IonButton,
  IonCheckbox,
  IonCol,
  IonInput,
  IonItem,
  IonLabel,
  IonRow,
  IonSelect,
  IonSelectOption,
  useIonLoading,
  useIonToast,
} from '@ionic/react';

import './AdvocatePostChatSurvey.scss';

import { ADVOCATE_TOAST_OPTIONS, ION_SELECT_INTERFACE_OPTIONS } from '../../../constants';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { getAdvocateSessionLog, setSessionLogsSurvey } from '../../../pages/advocate/AdvocateAccountSlice';
import { refreshToken } from '../../../pages/advocate/AdvocateAuthSlice';
import {
  getAdvocateAvailableStatus,
  resetPostChatSurvey,
  setAdvocatePostChatSurvey,
} from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateSubjects, saveAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

const OTHER_ID = 'd2d6aade-25ad-46bd-972a-9f86148dd116';

export const AdvocateChatSessionEndedPart3 = () => {
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const history = useHistory();
  const [presentLoading, dismissLoading] = useIonLoading();
  const [presentToast] = useIonToast();
  const subjects = useAppSelector((state) => state.advocateChatSlice.subjects);
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateChatSlice.advocatePostChatSurvey);
  const isSessionLogSurvey = useAppSelector((state) => state.advocateAccountSlice.isSessionLogSurvey);
  const sessionLog = useAppSelector((state) => state.advocateAccountSlice.sessionLog);
  const isOtherSelected = advocatePostChatSurvey.subject_ids.includes(OTHER_ID);

  useEffect(() => {
    dispatch(getAdvocateSubjects());
  }, [dispatch]);

  const validate = () => {
    let valid = true;
    let message = '';
    const oneSubjectSelected = advocatePostChatSurvey.subject_ids.length > 0;
    const otherInput = advocatePostChatSurvey.other_subject?.trim();
    const validOther = /\p{Letter}+/gu.test(otherInput ?? '');

    if (!oneSubjectSelected) {
      valid = false;
      message = 'Please select the nature of the conversation.';
    } else if (isOtherSelected && !validOther) {
      valid = false;
      message = 'Please specify the other topic.';
    }

    if (!valid) {
      presentToast({ ...ADVOCATE_TOAST_OPTIONS, message });
    }

    return valid;
  };

  const submit = async () => {
    const response = await dispatch(refreshToken());
    if (refreshToken.fulfilled.match(response) && response.payload.token && validate()) {
      presentLoading();
      const response = await dispatch(saveAdvocatePostChatSurvey(advocatePostChatSurvey));

      if (saveAdvocatePostChatSurvey.fulfilled.match(response) && response.payload === true) {
        capturePostHogCustomEvent('AdvocateChatSessionEndedPart3Component successfully submitted survey');
      }

      if (saveAdvocatePostChatSurvey.fulfilled.match(response) && response.payload === 'time_expired') {
        presentToast({
          ...ADVOCATE_TOAST_OPTIONS,
          message: 'Error: Survey expired. This survey ended more than an hour ago.',
          duration: 4500,
        });
        capturePostHogCustomEvent(
          'AdvocateChatSessionEndedPart3Component encountered a submission error due to survey expiry',
        );
      }

      if (saveAdvocatePostChatSurvey.rejected.match(response) && response.payload?.status !== 401) {
        presentToast({ ...ADVOCATE_TOAST_OPTIONS, message: 'Error while submitting survey.' });
        capturePostHogCustomEvent('AdvocateChatSessionEndedPart3Component encountered a submission error');
      }

      await dispatch(getAdvocateAvailableStatus());
      dispatch(resetPostChatSurvey());
      dismissLoading();

      if (isSessionLogSurvey) {
        dispatch(setSessionLogsSurvey(false));
        dispatch(getAdvocateSessionLog(sessionLog));
        history.push('/advocate/account');
      }
    }
  };

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="ion-padding-vertical ion-text-center headline-medium">Chat session ended</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonItem lines="none" mode="ios">
            <IonLabel className="title-medium" position="stacked">
              3. Nature of the conversation (required)
            </IonLabel>
            <IonSelect
              placeholder="Select topics"
              className="ion-margin-top app-ion-select"
              labelPlacement="floating"
              multiple={true}
              mode="ios"
              interface="popover"
              interfaceOptions={ION_SELECT_INTERFACE_OPTIONS}
              value={advocatePostChatSurvey.subject_ids}
              onIonChange={(e) => dispatch(setAdvocatePostChatSurvey({ subject_ids: e.detail.value ?? [] }))}
            >
              {subjects?.map(({ id, subject }) => (
                <IonSelectOption key={id} value={id}>
                  {subject}
                </IonSelectOption>
              ))}
            </IonSelect>
          </IonItem>
        </IonCol>
      </IonRow>
      {isOtherSelected && (
        <IonRow>
          <IonCol>
            <IonLabel className="inputTitle" position="stacked">
              Other topic
            </IonLabel>
            <IonItem lines="none" mode="ios">
              <IonInput
                className="input"
                value={advocatePostChatSurvey.other_subject}
                id="other"
                name="Other"
                placeholder="Type topic here"
                onIonInput={(e) => dispatch(setAdvocatePostChatSurvey({ other_subject: e.detail.value }))}
              />
            </IonItem>
          </IonCol>
        </IonRow>
      )}
      <IonRow>
        <IonCol>
          <IonItem lines="none" mode="ios">
            <IonCheckbox
              className="trainingCheckBox title-small"
              color="secondary"
              slot="start"
              mode="md"
              labelPlacement="end"
              checked={advocatePostChatSurvey.is_training}
              onIonChange={(e) => {
                capturePostHogCustomEvent(
                  `AdvocateChatSessionEndedPart3Component ${e.detail.checked ? 'checked' : 'unchecked'} training checkbox`,
                );
                dispatch(setAdvocatePostChatSurvey({ is_training: e.detail.checked }));
              }}
            >
              <IonLabel>This was a training chat.</IonLabel>
            </IonCheckbox>
          </IonItem>
        </IonCol>
      </IonRow>

      <IonRow className="ion-justify-content-center ion-padding-vertical">
        <IonCol>
          <IonButton className="defaultButtonBlue" mode="ios" expand="block" shape="round" onClick={submit}>
            Submit
          </IonButton>
        </IonCol>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
