import { Capacitor } from '@capacitor/core';
import { type DeviceInfo } from '@capacitor/device';
import type { LocalNotificationsPlugin } from '@capacitor/local-notifications';
import type { PushNotificationsPlugin, Token } from '@capacitor/push-notifications';

import { saveNotificationToken } from '../api/notification';
import { handleNotification } from '../helpers/notification.helper';
import { RaygunErrorHandlerService } from '../services/raygun';

const { logError } = RaygunErrorHandlerService();

const setNotificationInitiated = () => {
  // updated flag to trigger OS push re-registration for Critical Alerts (iOS)/Don't Disturb (Android)
  localStorage.setItem('user-init-notification2', 'true');
};

export const getNotificationInitiated = () => {
  return localStorage.getItem('user-init-notification2') === 'true';
};

export class PushNotification {
  private pushNotifications: PushNotificationsPlugin | null;
  private localNotifications: LocalNotificationsPlugin | null;
  private platform = Capacitor.getPlatform() as DeviceInfo['platform'];

  constructor() {
    this.pushNotifications = null;
    this.localNotifications = null;
  }

  private async checkDeviceAndRequestPermissions(): Promise<boolean> {
    const { PushNotifications } = await import('@capacitor/push-notifications');
    const { LocalNotifications } = await import('@capacitor/local-notifications');
    this.pushNotifications = PushNotifications;
    this.localNotifications = LocalNotifications;

    if (this.platform === 'web') {
      // It is done by Firebase notification
      return false;
    }

    const notInitiatedYet = !getNotificationInitiated();

    if (this.platform === 'ios') {
      const { receive } = notInitiatedYet
        ? await this.pushNotifications.requestPermissions()
        : await this.pushNotifications.checkPermissions();

      if (!receive && receive !== 'granted') {
        alert('You must grant push notification permission for this app to work properly');
        return false;
      }
    }

    if (this.platform === 'android') {
      const { display } = notInitiatedYet
        ? await this.localNotifications.requestPermissions()
        : await this.localNotifications.checkPermissions();

      if (!display && display !== 'granted') {
        alert('You must grant push notification permission for this app to work properly');
        return false;
      }
    }

    if (notInitiatedYet) {
      setNotificationInitiated();
    }

    return true;
  }

  public async register(): Promise<boolean> {
    try {
      const isDeviceReady = await this.checkDeviceAndRequestPermissions();

      if (!isDeviceReady) {
        return false;
      }

      this.pushNotifications?.removeAllListeners(); // clear listeners before adding new ones (to avoid listener stacking bugs)

      this.addListeners();

      await this.pushNotifications?.register();

      if (this.platform === 'android') {
        await this.pushNotifications?.createChannel({
          id: 'first_alerts_channel',
          name: 'First Alerts',
          description: 'First Alerts description.',
          sound: 'futuristic_alert_770_10.wav',
          importance: 5,
          visibility: 1,
          lights: true,
          vibration: true,
        });
      }
    } catch (error) {
      logError(error, ['notification', 'PushNotification', 'register']);
    }

    return true;
  }

  private updateUserPushNotificationToken = (token: string) => saveNotificationToken(token);

  private addListeners() {
    this.pushNotifications?.addListener('registration', async (token: Token) => {
      await this.updateUserPushNotificationToken(token.value);
    });

    this.pushNotifications?.addListener('registrationError', (error) => {
      logError(error, ['notification', 'PushNotification', 'registrationError']);
    });

    this.pushNotifications?.addListener('pushNotificationReceived', async (event) => {
      const { App } = await import('@capacitor/app');
      const state = await App.getState();
      handleNotification(event, state.isActive ? 'foreground' : 'background');
    });

    this.pushNotifications?.addListener('pushNotificationActionPerformed', (event) => {
      handleNotification(event.notification, 'background');
    });
  }
}

export enum PushType {
  NewChat = 'NewChat',
  NewMessage = 'NewMessage',
  CancelledChat = 'CancelledChat',
  ExpiredChat = 'ExpiredChat',
  ErrorChat = 'ErrorChat',
  Alert = 'Alert',
}

export type NotificationContent = {
  id: string;
  title: string;
  body: string;
  sent_by: string;
  sent_timestamp: string;
};

export type DartNotificationContent = NotificationContent & {
  end_timestamp: string;
};
