import { settingsOutline } from 'ionicons/icons';
import { useHistory } from 'react-router';

import { IonButton, IonButtons, IonCol, IonGrid, IonHeader, IonIcon, IonRow, IonTitle, IonToolbar } from '@ionic/react';

import { TSButton } from '../../components/atomic';
import { getCustomIcon } from '../../helpers/utils.helper';
import { useAppDispatch, useAppSelector } from '../../store';
import { GradientHeader } from '../../theme/GradientHeader';
import { ACCOUNT_COMPONENT, setCurrentComponent } from './AdvocateAccountSlice';
import { ADVOCATE_COMPONENT } from './AdvocateChatSlice';

type ProfileHeaderConfig = {
  component: ACCOUNT_COMPONENT;
  prevComponent?: ACCOUNT_COMPONENT;
  title: string;
};

const profileHeaderConfigs: ProfileHeaderConfig[] = [
  {
    component: ACCOUNT_COMPONENT.PROFILE,
    title: 'Profile',
  },
  {
    component: ACCOUNT_COMPONENT.EDIT_PROFILE,
    prevComponent: ACCOUNT_COMPONENT.PROFILE,
    title: 'Edit profile',
  },
  {
    component: ACCOUNT_COMPONENT.LIVED_EXPERIENCES,
    prevComponent: ACCOUNT_COMPONENT.EDIT_PROFILE,
    title: 'Lived experiences',
  },
  {
    component: ACCOUNT_COMPONENT.CHANGE_PASSWORD,
    prevComponent: ACCOUNT_COMPONENT.PROFILE,
    title: 'Change password',
  },
];

const SettingsIconHeader = () => {
  const history = useHistory();

  return (
    <TSButton
      onClick={() => {
        history.push('/settings');
      }}
      className="advocate-page-settings-button"
      variant="grey8"
    >
      <IonIcon className="title-large" slot="icon-only" icon={settingsOutline} />
    </TSButton>
  );
};

export const AdvocatePageHeader = ({ advocatePageTitle }: { advocatePageTitle: 'profile' | 'resources' | 'chat' }) => {
  const dispatch = useAppDispatch();
  const currentProfileComponent = useAppSelector((state) => state.advocateAccountSlice.currentComponent);
  const currentProfileHeaderConfig = profileHeaderConfigs.find(
    (config) => config.component === currentProfileComponent,
  );
  const advocateChatPageStatus = useAppSelector((state) => state.advocateChatSlice.advocateChatPageStatus);
  const hasGradientHeader = ![ADVOCATE_COMPONENT.CHAT].includes(advocateChatPageStatus);

  const showHeader = () => {
    switch (advocatePageTitle) {
      case 'chat':
        return (
          hasGradientHeader && (
            <>
              <GradientHeader pageColor="brown" />
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeSm="4">
                  <IonToolbar>
                    <IonRow className="ion-justify-content-end ion-align-items-center ion-margin-horizontal">
                      <SettingsIconHeader />
                    </IonRow>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </>
          )
        );
      case 'profile':
        return (
          currentProfileHeaderConfig && (
            <IonHeader className="ion-no-border ion-padding-horizontal">
              <IonGrid className="ion-no-padding">
                <IonRow className="ion-justify-content-center">
                  <IonCol size="12" sizeSm="4">
                    <IonToolbar className="pageToolbar">
                      {currentProfileHeaderConfig.prevComponent && (
                        <IonButtons className="ion-no-margin" slot="start">
                          <IonButton
                            onClick={() => {
                              if (currentProfileHeaderConfig.prevComponent) {
                                dispatch(setCurrentComponent(currentProfileHeaderConfig.prevComponent));
                              }
                            }}
                          >
                            <IonIcon src={getCustomIcon('chevron-left')} />
                          </IonButton>
                        </IonButtons>
                      )}
                      <IonTitle className="ion-text-center title-medium text-bold">
                        {currentProfileHeaderConfig.title}
                      </IonTitle>
                      <IonButtons className="ion-no-margin" slot="end">
                        <SettingsIconHeader />
                      </IonButtons>
                    </IonToolbar>
                  </IonCol>
                </IonRow>
              </IonGrid>
            </IonHeader>
          )
        );
      case 'resources':
        return (
          <IonHeader className="ion-no-border">
            <IonGrid>
              <IonRow className="ion-justify-content-center">
                <IonCol size="12" sizeSm="5">
                  <IonToolbar>
                    <IonRow className="ion-justify-content-end ion-align-items-center ion-margin-horizontal">
                      <SettingsIconHeader />
                    </IonRow>
                  </IonToolbar>
                </IonCol>
              </IonRow>
            </IonGrid>
          </IonHeader>
        );
      default:
        return;
    }
  };

  return showHeader();
};
