import { useTranslation } from 'react-i18next';

import type { ChatAdapter } from '@azure/communication-react';
import { IonButton, IonCard, IonCol, IonContent, IonGrid, IonRow } from '@ionic/react';

import { useAppSelector } from '../../store';

export const AdvocateEndChat = ({
  endChatModal,
  closeModal,
}: {
  endChatModal: (abuse: boolean) => Promise<void>;
  closeModal: () => void;
  chatAdapter: ChatAdapter | undefined;
  isAdvocate: boolean;
  advocateSentFirstMsg: boolean;
}) => {
  const { t } = useTranslation();
  const advocateReportedChatBy = useAppSelector((state) => state.advocateChatSlice.advocateReportedChatBy);

  return (
    <IonContent fullscreen>
      <IonGrid className="page-grid-align-vertical">
        <IonRow className="ion-justify-content-center ion-align-items-center page-grid-align-vertical">
          <IonCol sizeSm="4" size="12">
            <IonRow className="ion-margin">
              <IonCard className="generic-card ion-padding">
                <IonRow>
                  <IonCol className="ion-text-center headline-medium">{t('GuestEndChat.title')}</IonCol>
                </IonRow>
                <IonRow>
                  <IonCol className="ion-text-center">
                    {advocateReportedChatBy
                      ? t('GuestEndChat.advocateReportedAbuseSubTitle')
                      : t('GuestEndChat.subTitle')}
                  </IonCol>
                </IonRow>
                <IonRow className="ion-margin">
                  <IonCol size="6">
                    <IonButton
                      className="defaultButtonBlue"
                      mode="ios"
                      expand="block"
                      shape="round"
                      onClick={closeModal}
                    >
                      {t('generic.no')}
                    </IonButton>
                  </IonCol>
                  <IonCol size="6">
                    <IonButton
                      className="defaultButtonRed"
                      mode="ios"
                      expand="block"
                      shape="round"
                      onClick={() => endChatModal(false)}
                    >
                      {t('generic.yes')}
                    </IonButton>
                  </IonCol>
                </IonRow>
              </IonCard>
            </IonRow>
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonContent>
  );
};
