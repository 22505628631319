import { useEffect } from 'react';

import {
  IonButton,
  IonCol,
  IonItem,
  IonLabel,
  IonRadio,
  IonRadioGroup,
  IonRange,
  IonRow,
  IonTextarea,
  useIonToast,
} from '@ionic/react';

import { ABUSIVE_REASON_ID, ADVOCATE_TOAST_OPTIONS } from '../../../constants';
import { useCapturePostHog } from '../../../hooks/useCapturePostHog';
import { ADVOCATE_COMPONENT, setAdvocatePostChatSurvey } from '../../../pages/advocate/AdvocateChatSlice';
import { getAdvocateEndReasons, setAdvocateChatPageStatus } from '../../../pages/advocate/AdvocateChatSlice';
import { useAppDispatch, useAppSelector } from '../../../store';
import { AdvocatePostChatSurveyContainer } from './AdvocatePostChatSurveyContainer';

const requiredCommentMessage = 'You must provide comments because abuse has been reported.';

export const AdvocateChatSessionEndedPart1 = () => {
  const dispatch = useAppDispatch();
  const { capturePostHogCustomEvent } = useCapturePostHog();
  const [present] = useIonToast();
  const endReasons = useAppSelector((state) => state.advocateChatSlice.endReasons);
  const advocatePostChatSurvey = useAppSelector((state) => state.advocateChatSlice.advocatePostChatSurvey);
  const guestReportedAbuse = useAppSelector((state) => state.advocateChatSlice.guestReportedAbuse);
  const advocateReportedAbuse = useAppSelector((state) => state.advocateChatSlice.advocateReportedAbuse);
  const isAbuseReported =
    guestReportedAbuse || advocateReportedAbuse || advocatePostChatSurvey.end_reason_id === ABUSIVE_REASON_ID;
  const isAdvocateAbuseReported = advocateReportedAbuse || advocatePostChatSurvey.end_reason_id === ABUSIVE_REASON_ID;

  useEffect(() => {
    dispatch(getAdvocateEndReasons());
  }, [dispatch]);

  return (
    <AdvocatePostChatSurveyContainer>
      <IonRow>
        <IonCol className="ion-padding-vertical ion-text-center headline-medium">Chat session ended</IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          Please complete the survey in the next 10 minutes. The survey is required to qualify for compensation.
        </IonCol>
      </IonRow>
      <IonRow>
        <IonCol className="ion-text-center">
          <IonItem lines="none" mode="ios">
            <IonLabel className="title-medium" position="stacked">
              1. Reason for the chat ending (required)
            </IonLabel>
            <IonRadioGroup
              value={advocatePostChatSurvey.end_reason_id}
              onIonChange={(e) => {
                dispatch(setAdvocatePostChatSurvey({ end_reason_id: e.detail.value }));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component selected chat ended reason', {
                  end_reason_id: e.detail.value,
                });
              }}
            >
              {endReasons?.map((endReason) => (
                <IonItem key={endReason.id} lines="none">
                  <IonRadio
                    justify="space-between"
                    color="secondary"
                    labelPlacement="end"
                    mode="md"
                    slot="start"
                    value={endReason.id}
                    disabled={advocateReportedAbuse && endReason.id !== ABUSIVE_REASON_ID}
                  >
                    <IonLabel className="wrap-text"> {endReason.reason}</IonLabel>
                  </IonRadio>
                </IonItem>
              ))}
            </IonRadioGroup>
          </IonItem>
        </IonCol>
      </IonRow>
      {isAdvocateAbuseReported && (
        <IonRow>
          <IonRange
            mode="ios"
            snaps={true}
            ticks={true}
            min={1}
            max={5}
            step={1}
            onIonChange={({ detail }) => {
              dispatch(setAdvocatePostChatSurvey({ abuse_rating: detail.value as number }));
              capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component selected abuse rating', {
                rating: detail.value,
              });
            }}
          >
            <div slot="start">Low</div>
            <div slot="end">High</div>
          </IonRange>
        </IonRow>
      )}
      <IonRow>
        <IonCol className="ion-text-center">
          <IonItem lines="none" mode="ios">
            <IonTextarea
              className="input feedbackTextarea"
              rows={6}
              placeholder={isAbuseReported ? requiredCommentMessage : 'Leave an optional comment.'}
              id="feedback"
              name="feedback"
              onIonInput={(e) => dispatch(setAdvocatePostChatSurvey({ comments: e.detail.value }))}
            />
          </IonItem>
        </IonCol>
      </IonRow>
      <IonRow className="ion-justify-content-center">
        <IonCol size="6" className="ion-padding-vertical">
          <IonButton
            className="defaultButtonBlue"
            mode="ios"
            expand="block"
            shape="round"
            onClick={() => {
              if (!advocatePostChatSurvey.end_reason_id) {
                present({ ...ADVOCATE_TOAST_OPTIONS, message: 'Please select a reason for the chat ending.' });
                capturePostHogCustomEvent(
                  'AdvocateChatSessionEndedPart1Component encountered a submission error due to missing end reason',
                );
              } else if (isAbuseReported && !advocatePostChatSurvey.comments) {
                present({ ...ADVOCATE_TOAST_OPTIONS, message: requiredCommentMessage });
                capturePostHogCustomEvent(
                  'AdvocateChatSessionEndedPart1Component encountered a submission error due to missing comment',
                );
              } else {
                dispatch(setAdvocateChatPageStatus(ADVOCATE_COMPONENT.POST_CHAT_SURVEY_2));
                capturePostHogCustomEvent('AdvocateChatSessionEndedPart1Component entered part 2 of the survey');
              }
            }}
          >
            Next
          </IonButton>
        </IonCol>
      </IonRow>
    </AdvocatePostChatSurveyContainer>
  );
};
